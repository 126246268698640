<script>
import MenuFieldForm from "@/views/menu/menu/menu-field/menuFieldForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    menuService: "menuService",
    fileUploadService: "fileUploadService"
  }
})
export default class MenuFieldNew extends mixins(MenuFieldForm) {
  formId = "new-menu-field-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save
      }
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
      this.model.linkData.hotspotOptions = "{}";
      this.model.linkData.localizations = [
        ...this.model.linkData.hotspotLocalizations,
      ];

      await this.menuService.createField(this.model.idMenu, this.model);
      this.$router.push(`/menu-fields/${this.model.idMenu}/list`);
      toastService.success(this.translations.success.menuField_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle () {
    return this.translations.pageTitles.menuFields_new;
  }

  afterCreate () {
    this.model = {
      idMenu: this.$route.params.idParent,
      icon: null,
      order: null,
      localizations: [],
      linkData: {
        from: this.$route.params.idParent,
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        icon: null,
        type: null,
        to: null,
        externalLink: null,
        subType: null,
        autoOpen: null,
        hotspotEnabled: false,
        hotspotType: null,
        hotspotPosition: null,
        hotspotAnimation: null,
        hotspotLocalizations: [],
        localizations: []
      }
    };
    this.isReady = true;
  }
}
</script>
